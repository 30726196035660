import { useState, useEffect, useMemo, useRef, useContext } from 'react'
import useSWR from 'swr'
import { red, green, grey, orange } from '@ant-design/colors'
import {
  Form,
  Empty,
  Spin,
  Button,
  Row,
  Col,
  Card,
  Input,
  Checkbox,
  Space,
  message,
  List,
  Modal,
  Collapse,
  notification,
  Typography
} from 'antd'
import { InfoCircleOutlined, CheckCircleOutlined, WarningOutlined } from '@ant-design/icons'
// import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import Gaps from './Gaps'
import FulfillmentService from '../network/services/fulfillment'
import OrderService from '../network/services/order'
import { useTranslation } from 'react-i18next'
import { handheldScannerContext } from '../pages/scanner/Handheld'
const shiftCharCode = (Δ) => (c) => String.fromCharCode(c.charCodeAt(0) + Δ)
const toHalfWidth = (str) => str?.replace(/[！-～]/g, shiftCharCode(-0xfee0))

const OrderScanningCard = ({ trackingNumber, active = true }) => {
  // const asciiTracking = toHalfWidth(trackingNumber)
  const { t } = useTranslation('translation', { keyPrefix: 'orderScanning' })
  const { focusTrackingNumber } = useContext(handheldScannerContext)

  const {
    data: response,
    error,
    mutate
  } = useSWR(FulfillmentService.byTracking(['ヤマト運輸', 'Yamato (JA)'], trackingNumber))
  const fulfillment = _.first(response)

  const fulfillSuccess = async () => {
    focusTrackingNumber && focusTrackingNumber()
  }

  if (!response) {
    return (
      <Card>
        <Spin />
      </Card>
    )
  }

  //TODO: add translation
  if (error || !fulfillment) {
    return (
      <Card>
        <Empty description={`No such fulfillment (tracking number : ${trackingNumber})`} />
      </Card>
    )
  }

  const order = fulfillment?.order

  //TODO: add translation
  if (!order) {
    return (
      <Card>
        <Empty description={`No such order (tracking number : ${trackingNumber})`} />
      </Card>
    )
  }

  return (
    <Card
      title={`Order #${order?.id ?? ''}${order?.name ? ' / ' : ''}${order?.name ?? ''}${
        order?.platform_order_id ? ' / ' : ''
      }${order?.platform_order_id ?? ''}`}
      style={{
        backgroundColor: active ? 'auto' : '#fafafa'
      }}
    >
      {order?.order_status_id === 5 && (
        <h2 style={{ color: green.primary }}>{t('Order was successfully fulfilled')}</h2>
      )}
      {order?.order_status_id === 6 && (
        <h2 style={{ color: orange.primary }}>{t('Order was partially fulfilled')}</h2>
      )}
      {order?.order_status_id === 7 && <h2>{t('Order was cancelled')}</h2>}
      <PageWrapper
        fulfillment={fulfillment}
        fulfillSuccess={fulfillSuccess}
        active={active}
        mutate={mutate}
      />
    </Card>
  )
}

// const ProductWarpper = ({ order }) => {
//   const { data: products } = useSWR(serialize(OrderService.getProducts(order.id)))

//   if (!products) {
//     return <Spin />
//   }

//   return <PageWrapper products={products} />
// }

const PageWrapper = ({ fulfillment, fulfillSuccess, active, mutate }) => {
  //TODO: make a memo for the list item for submit and check if length > 0 for enabling partially fulfill button

  const order = useMemo(() => fulfillment?.order, [fulfillment])
  const isFulfilled = useMemo(() => order?.order_status_id === 5, [order])
  const isPending = useMemo(
    () => fulfillment?.fulfillment_status_id === 1 && fulfillment?.order.order_status_id !== 7,
    [fulfillment]
  )
  const { t } = useTranslation('translation', { keyPrefix: 'orderScanning' })

  const { data: blacklists } = useSWR(active && order ? OrderService.getBlacklists(order.id) : null)

  const [form] = Form.useForm()
  const [barcodeForm] = Form.useForm()
  // const navigate = useNavigate()
  const errorAudio = new Audio('/audio/error.mp3')
  const successAudio = new Audio('/audio/success.mp3')
  const barcodeInputRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [checkMessage, setCheckMessage] = useState(false)
  const [blacklistModalOpen, setBlacklistModalOpen] = useState(false)
  // const [delayDebounce, setDelayDebounce] = useState()
  const [lineItemCheckList, setLineItemCheckList] = useState([])
  // const [productChecklist, setProductChecklist] = useState([])
  const [disable, setDisable] = useState(true)
  const [notifyCustomer, setNotifyCustomer] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  // const [barcode, setBarcode] = useState('')
  const fulfillingItems = useMemo(() => {
    const line_items = []
    lineItemCheckList.forEach((item) => {
      if (item.currentQty > 0) {
        line_items.push({
          line_item_id: item.id,
          quantity: item.currentQty
        })
      }
    })
    return line_items
  }, [lineItemCheckList])

  useEffect(() => {
    active && barcodeInputRef?.current?.focus()
  })

  const handleCheckProcuct = (barcode) => {
    if (!barcode) return
    setErrorMessage('')
    const asciiBarcode = toHalfWidth(barcode)
    const index = lineItemCheckList.findIndex(
      (item) => item.product_variant.barcode === asciiBarcode
    )
    if (index !== -1) {
      const newList = [...lineItemCheckList]
      if (newList[index].currentQty + 1 <= newList[index].quantity) {
        newList[index].currentQty += 1
        setLineItemCheckList([...newList])
        successAudio.play()
        return
      }
      setErrorMessage('Maximun amount reached')
      errorAudio.play()
      return
    }
    errorAudio.play()
    setErrorMessage('Scanned barcode does not match any product in the list')
  }

  const handleBarcodeSubmit = (data) => {
    handleCheckProcuct(data.barcode)
    barcodeForm.resetFields()
    barcodeInputRef?.current?.focus()
  }

  const handleMarkAsScanned = (lineItemId) => {
    const index = lineItemCheckList.findIndex((item) => item.id === lineItemId)
    if (index !== -1) {
      const newList = [...lineItemCheckList]
      newList[index].currentQty = newList[index].fulfillable_quantity
      setLineItemCheckList([...newList])
    }
  }
  // const handleInput = (value) => {
  //   clearTimeout(delayDebounce)
  //   if (value) {
  //     const deplayDebounce = setTimeout(() => {
  //       const asciiValue = toHalfWidth(value)
  //       handleCheckProcuct(asciiValue)
  //       setBarcode('')
  //     }, 500)
  //     setDelayDebounce(deplayDebounce)
  //   }
  // }

  const handleCompleteFulfillment = () => {
    if (disable || !checkMessage) return
    if (blacklists?.length > 0) {
      setBlacklistModalOpen(true)
      return
    }
    handleFulfillment()
  }

  const handlePartialFulfillment = () => {
    if (window.confirm(t('Are you sure you want to partially fulfill this order?'))) {
      if (blacklists?.length > 0) {
        setBlacklistModalOpen(true)
        return
      }
      handleFulfillment()
    }
  }

  const onBlacklistModalOk = () => {
    setBlacklistModalOpen(false)
    handleFulfillment()
  }

  const onBlacklistModalCancel = () => {
    setBlacklistModalOpen(false)
  }

  const handleFulfillment = async () => {
    //if the fulfillment is not a pending fulfillment this function shall not be called
    if (!isPending) return
    //TODO: display the loading order at the top left corner of the screen and scroll back to barcode input
    fulfillSuccess()
    notification.open({
      message: `Fulfilling order #${order.id} / ${order.name} ...`,
      icon: <Spin />,
      description: (
        <Space direction="vertical">
          {fulfillingItems.map((item) => {
            const oriItem = order.line_items.find((ori) => `${ori.id}` === `${item.id}`)
            return oriItem ? (
              <Typography.Text>
                {oriItem.name} X {item.quantity}
              </Typography.Text>
            ) : (
              <></>
            )
          })}
        </Space>
      ),
      duration: 0,
      key: fulfillment.id,
      placement: 'topLeft'
    })
    const line_items = fulfillingItems
    setLoading(true)
    // message.loading('fulfilling order')
    try {
      const { data: result } = await FulfillmentService.fulfill(fulfillment.id, {
        notify_customer: notifyCustomer,
        line_items: line_items
      })
      if (result.success) {
        notification.close(fulfillment.id)
        message.success('Fulfillment Successful')
        await mutate()
        // setTimeout(() => {
        //   navigate('/scanner/handheld')
        // }, 400)
      }
    } catch (error) {
      notification.close(fulfillment.id)
      notification.open({
        icon: (
          <Typography.Text style={{ color: red.primary }}>
            <WarningOutlined />
          </Typography.Text>
        ),
        message: (
          <Typography.Text
            style={{ color: red.primary }}
          >{`Fail to fulfill order #${order.id} / ${order.name}`}</Typography.Text>
        ),
        description: error.message,
        placement: 'topLeft',
        duration: 0
      })
      // message.error('Order fulfillment error ' + error.message)
    }
    setLoading(false)
  }

  useEffect(() => {
    // const focusInput = () => barcodeInputRef?.current && barcodeInputRef?.current?.focus()
    const handleEnter = (e) => {
      active && e.key === 'Enter' && handleCompleteFulfillment()
    }

    // focusInput()

    // window.addEventListener('click', focusInput)
    window.addEventListener('keydown', handleEnter)

    return () => {
      //   window.removeEventListener('click', focusInput)
      window.removeEventListener('keydown', handleEnter)
    }
  })

  useEffect(() => {
    const checklist = order.line_items.map((item) => {
      return {
        ...item,
        currentQty: 0
      }
    })
    setLineItemCheckList([...checklist])
  }, [order.line_items])

  useEffect(() => {
    setCheckMessage(!order.customer_memo && !order.operator_memo)
  }, [order.customer_memo, order.operator_memo])

  useEffect(() => {
    if (lineItemCheckList?.length > 0) {
      var allChecked = true
      for (var product of lineItemCheckList) {
        if (product.fulfillable_quantity !== product.currentQty) {
          // console.log(product.variant_title, product.fulfillable_quantity, product.currentQty)
          allChecked = false
          break
        }
      }
      setDisable(!allChecked)
      return
    }
    setDisable(true)
  }, [lineItemCheckList])

  return (
    <>
      <BlacklistModal
        isOpen={blacklistModalOpen}
        blacklists={blacklists}
        onOk={onBlacklistModalOk}
        onCancel={onBlacklistModalCancel}
      />
      <Row gutter={[24, 24]}>
        <Col span={24}>
          {blacklists?.length > 0 && (
            <>
              <Gaps />
              <Card style={{ background: red[0] }}>
                <Space direction="vertical">
                  <div style={{ color: red.primary, fontWeight: 'bold' }}>
                    {`${blacklists.length} blacklisted individual(s) detected. Following blacklisted`}
                    individuals found matched
                  </div>

                  {blacklists.map((item) => (
                    <Collapse>
                      <Collapse.Panel header={item.name}>
                        <Space direction="vertical">
                          <div>{item.email}</div>
                          <div>
                            {item?.address} {item?.appartment}
                          </div>
                          {item?.company && <div>{item.company}</div>}
                          <div>Remark : {item.remark}</div>
                        </Space>
                      </Collapse.Panel>
                    </Collapse>
                  ))}
                </Space>
              </Card>
              <Gaps />
            </>
          )}
          <Form
            form={form}
            disable
            initialValues={{
              ...order,
              customer_email: order?.customer.email,
              shop_platform: order?.shop_platform?.name,
              customer_name: order?.customer?.name,
              full_address: `${order?.zip ?? ''} ${order?.address ?? ''} ${order?.apartment ?? ''}`,
              tracking_number: fulfillment.tracking_number,
              tracking_company_name: fulfillment.tracking_company_name,
              fulfillment_status: fulfillment.status.status
            }}
            onFinish={() => console.log('finished')}
          >
            <Form.Item name="customer_name" label="Customer Name">
              <Input readOnly bordered={false} style={{ fontWeight: 'bold' }} />
            </Form.Item>
            {/* <Form.Item name="customer_email" label="Customer Email">
              <Input readOnly bordered={false} style={{ fontWeight: 'bold' }} />
            </Form.Item> */}
            <Form.Item name="full_address" label="Address">
              <Input readOnly bordered={false} style={{ fontWeight: 'bold' }} />
            </Form.Item>
            <Form.Item name="tracking_number" label="Tracking Number">
              <Input readOnly bordered={false} style={{ fontWeight: 'bold' }} />
            </Form.Item>

            <Collapse ghost>
              <Collapse.Panel header="More details">
                <Form.Item name="company" label="Company">
                  <Input readOnly bordered={false} style={{ fontWeight: 'bold' }} />
                </Form.Item>
                <Form.Item name="shop_platform" label="Shop Platform">
                  <Input readOnly bordered={false} style={{ fontWeight: 'bold' }} />
                </Form.Item>
                <Form.Item name="tracking_company_name" label="Tracking company">
                  <Input readOnly bordered={false} style={{ fontWeight: 'bold' }} />
                </Form.Item>
                <Form.Item name="fulfillment_status" label="Fulfillment status">
                  <Input readOnly bordered={false} style={{ fontWeight: 'bold' }} />
                </Form.Item>
              </Collapse.Panel>
            </Collapse>
          </Form>
          {/* TODO: display order status */}
          <div
          // bordered={false}
          // title={isFulfilled ? 'This Order Has Already Been Fulfilled' : 'Products Ordered'}
          >
            {active && isPending && (
              <Form form={barcodeForm} onFinish={handleBarcodeSubmit}>
                <Row justify="center">
                  <Col>
                    <Form.Item name="barcode">
                      <Input
                        disabled={loading}
                        autoComplete="off"
                        ref={barcodeInputRef}
                        placeholder="Scan a product barcode"
                        style={{ opacity: 1 }}
                      />
                    </Form.Item>
                  </Col>
                  <Gaps />
                  <Col>
                    <Button onClick={() => barcodeForm.submit()} type="primary" loading={loading}>
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
            <p style={{ color: red.primary }}>{errorMessage}</p>
            <Gaps />
            <List
              grid={{ gutter: 16, column: 1 }}
              dataSource={lineItemCheckList}
              renderItem={(product) => (
                <List.Item>
                  <ProductListItem
                    key={product.id}
                    product={product}
                    handleMarkAsScanned={handleMarkAsScanned}
                    fulfillmentStatusId={fulfillment.fulfillment_status_id}
                    orderStatusId={order.order_status_id}
                    active={active}
                  />
                </List.Item>
              )}
            />
            <Card bordered={false} title={'Message'}>
              <Space direction="vertical" style={{ width: '100%' }}>
                Customer memo
                <Input.TextArea rows={4} readOnly value={order?.customer_memo} />
              </Space>
              <Gaps />
              <Space direction="vertical" style={{ width: '100%' }}>
                Operator memo
                <Input.TextArea rows={4} readOnly value={order?.operator_memo} />
              </Space>
            </Card>
            {active && !isFulfilled && isPending && (
              <>
                <Row>
                  <Col>
                    <Checkbox checked={checkMessage} onClick={() => setCheckMessage(!checkMessage)}>
                      {t('I have read the message above')}
                    </Checkbox>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Checkbox
                      onClick={() => setNotifyCustomer(!notifyCustomer)}
                      checked={notifyCustomer}
                    >
                      {t('Notify customer')}
                    </Checkbox>
                  </Col>
                </Row>
                <Gaps />
                <Row>
                  <Col>
                    <Button
                      disabled={disable || !checkMessage}
                      loading={loading}
                      onClick={handleCompleteFulfillment}
                    >
                      {t('Confirm shipment')}
                    </Button>
                  </Col>
                </Row>
                <Gaps />
                <Row>
                  <Col>
                    <Space direction="vertical">
                      <div style={{ color: red.primary }}>
                        {t('*The items that were not scanned will NOT be shipped')}
                      </div>
                      <Button
                        loading={loading}
                        onClick={handlePartialFulfillment}
                        disabled={!(fulfillingItems?.length > 0)}
                      >
                        {t('Ship Partially')}
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </>
            )}
          </div>
        </Col>
      </Row>
    </>
  )
}

const ProductListItem = ({
  product,
  handleMarkAsScanned,
  fulfillmentStatusId,
  orderStatusId,
  active
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'orderScanning' })
  const fulfilled = useMemo(() => orderStatusId === 5, [orderStatusId])
  const partiallyFulfilled = useMemo(() => orderStatusId === 6, [orderStatusId])
  const isPending = useMemo(
    () => fulfillmentStatusId === 1 && orderStatusId !== 7,
    [fulfillmentStatusId, orderStatusId]
  )
  const scanSuccess = useMemo(() => {
    return product.currentQty === product.fulfillable_quantity
  }, [product.currentQty, product.fulfillable_quantity])
  return (
    <div id={product.key}>
      <Card
        style={{
          backgroundColor:
            fulfilled || (partiallyFulfilled && !isPending)
              ? '#f0f0f0'
              : scanSuccess
              ? green[1]
              : red[1]
        }}
      >
        <p>
          {product.name} X {product.quantity}
        </p>
        <p>SKU : {product.sku}</p>
        <p>Barcode : {product?.product_variant?.barcode ?? ''}</p>
        <Gaps />

        {!fulfilled && product.fulfillable_quantity > 0 && (
          <Row>
            <Col>
              {partiallyFulfilled && !isPending ? (
                <Space>
                  {product.quantity - product.fulfillable_quantity} / {product.quantity} fulfilled
                </Space>
              ) : (
                <Space style={{ color: scanSuccess ? '#1DA57A' : red.primary }}>
                  {product.currentQty} / {product.fulfillable_quantity} {t('scanned')}
                </Space>
              )}
            </Col>
          </Row>
        )}

        {!fulfilled &&
          product.fulfillable_quantity > 0 &&
          isPending &&
          active &&
          (!scanSuccess ? (
            <Space style={{ color: red.primary }}>
              <InfoCircleOutlined />
              Scan to Match
              <Button onClick={() => handleMarkAsScanned(product.id)} disabled={scanSuccess}>
                {t('Mark as scanned')}
              </Button>
            </Space>
          ) : (
            // TODO: use dynamic color
            <Space style={{ color: '#1DA57A' }}>
              <CheckCircleOutlined />
              Success
            </Space>
          ))}

        {/* if partially fulfilled */}

        {partiallyFulfilled && !isPending ? (
          <Space style={{ color: grey[0] }}>
            <CheckCircleOutlined />
            Partially Fulfilled
          </Space>
        ) : (
          (fulfilled || product.fulfillable_quantity <= 0) && (
            <Space style={{ color: grey[0] }}>
              <CheckCircleOutlined />
              Fulfilled
            </Space>
          )
        )}
      </Card>
    </div>
  )
}

const BlacklistModal = ({ isOpen, blacklists, onOk, onCancel }) => {
  return (
    blacklists?.length > 0 && (
      <Modal visible={isOpen} onOk={() => onOk()} onCancel={onCancel}>
        <Space direction="vertical">
          <div style={{ color: red.primary, fontWeight: 'bold' }}>
            {blacklists.length} Blacklisted individual(s) detected.
          </div>
          <div>Are you sure you still want to proceed with the fulfillment?</div>
          <div style={{ color: red.primary, fontWeight: 'bold' }}>
            Following blacklisted individuals found matched :
          </div>
          <Space direction="vertical" style={{ width: '100%' }}>
            {blacklists.map((item) => (
              <Collapse>
                <Collapse.Panel header={item.name}>
                  <Space direction="vertical">
                    <div>{item.email}</div>
                    <div>
                      {item?.address} {item?.appartment}
                    </div>
                    {item?.company && <div>{item.company}</div>}
                    <div>Remark : {item.remark}</div>
                  </Space>
                </Collapse.Panel>
              </Collapse>
            ))}
          </Space>
        </Space>
      </Modal>
    )
  )
}
export default OrderScanningCard
