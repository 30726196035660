import axios from 'axios'
import client, { serialize } from '../request'
import { getToken } from '../../lib/store/auth'
import commaNumber from 'comma-number'

// useSWR, return strings
const getAll = '/orders'

// useSWR, return strings
const get = (id) => {
  return `/orders/${id}`
}

const getProducts = (id) => {
  return `/orders/${id}/products`
}

const getTransactionStatuses = `/transaction-statusus`
const getFulfillmentStatuses = `/fulfillment-statusus`
const getOrderStatuses = `/order-statusus`

// added filter to ordercontroller, this is no longer needed
// const getByStatus = (id) => {
//   return `/order/status/${id}`
// }

// added filter to ordercontroller, this is no longer needed
// const getByTracking = (id) => {
//   return `order/tracking/${id}`
// }

// axios
const update = (id, data) => {
  return client.put(`/orders/${id}`, data)
}

const updateMemo = (id, data) => {
  return client.put(`/orders/${id}/memo`, data)
}

const getBlacklists = (id) => {
  return `/orders/${id}/blacklists`
}

const uploadYamatoCSV = (data) => {
  const token = getToken()
  return client.post('/orders/yamato-csv', data, {
    headers: {
      contentType: 'multipart/form-data',
      Authorization: `Bearer ${token}`
    }
  })
}

const sendEmailFromTemplate = (id, templateId) => {
  return client.put(`/orders/${id}/send-email`, {
    template_id: templateId.toString()
  })
}

const sendPreviewEmailFromTemplate = (id, templateId, email) => {
  return client.put(`/orders/${id}/email-preview`, {
    template_id: templateId.toString(),
    email
  })
}

const orderFulfillment = (id, data) => {
  return client.put(`orders/${id}/fulfill`, data)
}

// processing
const toRow = (data) => {
  if (data?.data?.length > 0) {
    return data.data.map((element) => {
      const dateCreated = new Date(element?.created_at)
      const dateFulfilled = element?.fulfilled_at ? new Date(element?.fulfilled_at) : ''
      return {
        ...element,
        customer_email: element?.customer?.email,
        customer_name: element?.customer?.name,
        transaction_status: element?.transaction_status?.status,
        status: element?.status?.status,
        created_at: dateCreated.toLocaleString(),
        address: element?.address + (element?.apartment ?? ''),
        key: element?.id,
        total_amount: '￥' + commaNumber(element?.total_amount),
        generated: element?.generated ? 'Yes' : 'No',
        shop_platform: element?.shop_platform?.name,
        fulfilled_at: dateFulfilled?.toLocaleString() ?? ''
      }
    })
  }

  return []
}

// processing
const transactionStatusToRow = (data) => {
  if (data?.length > 0) {
    return data.map((element) => {
      return {
        ...element,
        name: element?.status,
        key: element?.id
      }
    })
  }

  return []
}

// processing
const statusToRow = (data) => {
  if (data?.length > 0) {
    return data.map((element) => {
      return {
        ...element,
        name: element?.status,
        key: element?.id
      }
    })
  }

  return []
}

// processing
const productToRow = (data) => {
  if (data?.length > 0) {
    return data.map((element) => {
      return {
        ...element,
        quantity: Math.abs(element?.quantity ?? 0),
        key: element?.id
      }
    })
  }

  return []
}

// processing
const toPaginate = (data) => {
  return {
    total: data?.meta?.total ?? 0
  }
}

//downloadCSV
// const download = () => {
//   return client.get(`/order/csv`)
// }

const download = (data) => {
  const requestHandler = (request) => {
    const token = localStorage.getItem('token') || undefined

    if (token != null && typeof token !== 'undefined') {
      request.headers.Authorization = `Bearer ${token}`
    }

    return request
  }

  const customClient = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL
  })
  customClient.interceptors.request.use((request) => requestHandler(request))

  return customClient.post('/orders/csv', data, {
    responseType: 'blob'
  })
}

const importShopify = () => {
  return client.get('orders/import/shopify')
}

const OrderService = {
  getAll,
  get,
  sendEmailFromTemplate,
  sendPreviewEmailFromTemplate,
  getTransactionStatuses,
  getFulfillmentStatuses,
  getOrderStatuses,
  getBlacklists,
  statusToRow,
  getProducts,
  toPaginate,
  update,
  updateMemo,
  uploadYamatoCSV,
  orderFulfillment,
  transactionStatusToRow,
  productToRow,
  toRow,
  download,
  importShopify
}

export default OrderService
